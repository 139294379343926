import * as React from 'react';
import load_img from "images/loading.svg";

const Loading = ({ state }) => {
    return (
        <>
            {state ?
                <div className="load">
                    <div className="load__icon-wrap">
                        <img src={load_img} alt="Loading..." />
                    </div>
                </div>
                :
                <></>
            }
        </>
    );
}

export default Loading;