import * as React from "react";

const Footer = () => {

    return (
        <div className="footer">

            <p>© 2023 - {new Date().getFullYear()} <b>Hit Colombia</b> todos los derechos reservados.</p>
            <p><i>Desarrollado por <strong>Sarguero</strong><br />{process.env.REACT_APP_BUILD_VERSION}</i></p>

        </div>
    )
}

export default Footer;