import * as React from 'react';
import FortgotForm from './components/FortgotForm';

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ForgotPassword = ({ changeTheme }) => {

    React.useEffect(() => {
        document.title = "Recuperar contraseña";
        let theme = localStorage.getItem("theme");
        changeTheme((theme == "theme-light" || theme == "theme-dark") ? theme : "theme-light");
    }, []);

    return (
        <main className="login">
            <div className="login__wrapper">
                <div className="login__card">

                    <div className="login__head">
                        <div className="login__head_logo"></div>
                        <h4 className="login__head_title">Ingresa tu correo electrónico para recuperar tu contraseña. <br />Recuerda que esta acción debe ser validada por un administrador.</h4>
                    </div>

                    <FortgotForm />

                    <p>© {new Date().getFullYear()} <b>Hit Colombia</b> todos los derechos reservados. <br />
                        <i>Desarrollado por <span>Sarguero</span><br />{process.env.REACT_APP_BUILD_VERSION}</i>
                    </p>
                </div>
            </div>
        </main>
    );
}

export default ForgotPassword;