import * as React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ItemLink = ({ title, route, state = {} }) => (

    <li className="item_link" onClick={e => {
        e.stopPropagation();
        let _element = document.getElementsByClassName("_opened")[0];
        _element && _element.classList.remove("_opened");
    }}>
        <NavLink
            to={route}
            state={state}
            className={({ isActive }) => isActive ? "item_link-active" : ""}>
            {title}
        </NavLink>
    </li>
);

ItemLink.propTypes = {
    title: PropTypes.string.isRequired,
    route: PropTypes.string,
};

ItemLink.defaultProps = {
    title: '...',
    route: '/',
};

export default ItemLink;
