import * as React from 'react';
import PropTypes from 'prop-types';

const ItemGroup = ({ title, Icono, children }) => (

    <ul className="item_group" onClick={e => {
        e.stopPropagation();
    }}>
        {Icono ? <Icono className="item_group-icon" /> : ''}
        <strong className="item_group-title">
            {title}
        </strong>
        {children}
    </ul>
);

ItemGroup.propTypes = {
    title: PropTypes.string.isRequired,
};

export default ItemGroup;
