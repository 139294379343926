import * as React from 'react';
import LogInForm from './components/LogInForm';

/* eslint eqeqeq: 0 */

const LogIn = ({ changeTheme }) => {

    React.useEffect(() => {
        document.title = "Iniciar sesión";
        let theme = localStorage.getItem("theme");
        changeTheme((theme == "theme-light" || theme == "theme-dark") ? theme : "theme-light");
        localStorage.removeItem('usuario');

        let _footer = document.getElementsByClassName("footer")[0];
        _footer.style.display = "none";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <main className="login">
            <div className="login__wrapper">
                <div className="login__card">

                    <div className="login__head">
                        <div className="login__head_logo"></div>
                        <h4 className="login__head_title">Bienvenido, inicia tu sesión para poder ingresar al sistema.</h4>
                    </div>

                    <LogInForm />

                    <p>© {new Date().getFullYear()} <b>Hit Colombia</b> todos los derechos reservados. <br />
                        <i>Desarrollado por <span>Sarguero</span><br />{process.env.REACT_APP_BUILD_VERSION}</i>
                    </p>
                </div>
            </div>
        </main>
    );
}

export default LogIn;